/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { graphql } from 'gatsby';
import ProductStage from 'components/common/Product/ProductStage';
import { slugify } from 'utils/slugify';

import Seo from 'components/container/Seo';

export default function TemplateCategoriesBeerVarietiesCats({
  data,
  pageContext,
  location,
}) {
  if (!data && !data.craft) {
    return null;
  }

  /**
   * Transform and combine scenery data
   */
  const sceneryImages = {
    bottle: data.bottle,
    layers: [
      data.sceneryLayer1,
      data.sceneryLayer2,
      data.sceneryLayer3,
      data.sceneryLayer4,
      data.sceneryLayer5,
    ],
  };

  /**
   * Transform and combine product detail data and sections
   */
  const {
    title,
    seoTitle,
    seoDescription,
    seoImage,
    url,
    productTitle,
    productBadges,
    productBuyLink,
    productTopline,
    shortDescription,
    nutrientsTable,
    productBeerContainers,
    productGradientColor1,
    productGradientColor2,
    products,
    productQualityLink: productQualityLinkLocal,
  } = pageContext;

  const {
    productContainersTitle,
    productContainersText,
    productQualityLink: productQualityLinkGlobal,
    productNutrientsTitle,
  } = data.craft.globals?.globalProducts || {};
  const { backgroundImage } = data;

  const productQualityLink =
    productQualityLinkLocal || productQualityLinkGlobal;

  //console.log({ productQualityLinkLocal, productQualityLinkGlobal });

  // Prepare products for product switch.
  const preparedProducts = products.map((product) => {
    // Find bottle image for product.
    const preparedProductBottle = data.bottles.edges.find(
      ({ node }) =>
        node.relativeDirectory.replace(/^beerVarietiesCats\//, '') ===
        product.slug,
    );

    return {
      id: product.id,
      title: product.entryTeaserTitle?.content,
      slug: product.slug,
      fullUri: `/${product.uri}`,
      bottle: preparedProductBottle ? preparedProductBottle.node : null,
      color: product.productGradientColor1,
      current: product.slug === pageContext.slug,
    };
  });

  const sections = [
    {
      // Intro
      key: 'top',
      slug: 'intro',
      title: productTitle,
      description: shortDescription,
      sectionLink: productBuyLink,
      topline: productTopline,
      badges: productBadges,
    },
    {
      // Containers
      key: 'hop', // just fyi: this determines the animation, so it should be either one of hop, barley, water or rename all the occurrences...
      slug: slugify(productContainersTitle.content),
      title: productContainersTitle,
      description: productContainersText?.content,
      sectionLink: productBuyLink,
      sectionSecondaryLink: productQualityLink,
      productBeerContainers,
    },
    {
      // Nutrients
      key: 'nutrients',
      slug: slugify(productNutrientsTitle.content),
      title: productNutrientsTitle,
      description: '',
      sectionLink: productBuyLink,
      nutrientsTable,
    },
    {
      key: '$top',
      slug: 'bottom',
      title: productTitle,
      description: shortDescription,
      sectionLink: productBuyLink,
      topline: productTopline,
    },
  ];

  const sectionSlugs = sections.reduce(
    (slugs, { key, slug }) => ({ [key]: slug, ...slugs }),
    {},
  );

  const sectionKeys = sections.reduce(
    (slugs, { key, slug }) => ({ [slug]: key, ...slugs }),
    {},
  );

  const gradient = [productGradientColor1?.hex, productGradientColor2?.hex];

  return (
    <>
      <Seo
        title={seoTitle || title}
        description={seoDescription}
        url={url}
        image={seoImage?.[0]?.socialMediaUrl}
      />
      <ProductStage
        products={preparedProducts}
        title={title}
        shortDescription={shortDescription}
        slug={pageContext.slug}
        sceneryImages={sceneryImages}
        sections={sections}
        sectionSlugs={sectionSlugs}
        gradient={gradient}
        backgroundImage={backgroundImage}
        initialSectionKey={
          sectionKeys[location.hash.substring(1)] || sections[0].key
        }
        topKeys={[sections[0].key, sections[sections.length - 1].key]}
      />
    </>
  );
}

export const query = graphql`
  query CategoriesBeerVarietiesCats($assetBase: String!) {
    # Getting bottles for all products.
    bottles: allFile(
      filter: {
        relativeDirectory: { regex: "/beerVarietiesCats/.*/" }
        name: { eq: "bottle" }
      }
    ) {
      edges {
        node {
          relativeDirectory
          childImageSharp {
            fixed(quality: 100, width: 340) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    }
    bottle: file(
      relativeDirectory: { eq: $assetBase }
      name: { eq: "bottle" }
    ) {
      childImageSharp {
        fixed(quality: 100, width: 340) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    sceneryLayer1: file(
      relativeDirectory: { eq: $assetBase }
      name: { eq: "scenery_layer_1" }
    ) {
      childImageSharp {
        fixed(quality: 100, width: 1080) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    sceneryLayer2: file(
      relativeDirectory: { eq: $assetBase }
      name: { eq: "scenery_layer_2" }
    ) {
      childImageSharp {
        fixed(quality: 100, width: 1080) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    sceneryLayer3: file(
      relativeDirectory: { eq: $assetBase }
      name: { eq: "scenery_layer_3" }
    ) {
      childImageSharp {
        fixed(quality: 100, width: 1080) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    sceneryLayer4: file(
      relativeDirectory: { eq: $assetBase }
      name: { eq: "scenery_layer_4" }
    ) {
      childImageSharp {
        fixed(quality: 100, width: 1080) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    sceneryLayer5: file(
      relativeDirectory: { eq: $assetBase }
      name: { eq: "scenery_layer_5" }
    ) {
      childImageSharp {
        fixed(quality: 100, width: 1080) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    backgroundImage: file(relativePath: { eq: "bg-top-left-transparent.jpg" }) {
      ...imageDefault
    }

    craft {
      globals(siteId: 8) {
        globalProducts {
          # Product section 2
          productContainersTitle {
            content
          }
          productContainersText {
            content
          }
          productQualityLink {
            target
            text
            url
            entry {
              id
              fullUri
            }
          }

          # Product section 3
          productNutrientsTitle {
            content
          }
        }
      }
    }
  }
`;
